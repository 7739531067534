import React from "react";
import { UserContext } from "lib/user";
import { useHistory, Link } from "react-router-dom";
import { Switch, Route } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import $ from "jquery";
import * as LTE from "components/admin-lte";
import SkinContext from "components/skin";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Feeders from "pages/Feeders";
import ConvertingOffers from "pages/reports/ConvertingOffers";
import NonConvertingOffers from "pages/reports/NonConvertingOffers";
import DirectOffers from "pages/reports/DirectOffers";
import OffersCountByApi from "pages/reports/OffersCountByApi";
import OffersCountByPackageName from "pages/reports/OffersCountByPackageName";
import BlacklistedApps from "pages/tools/BlacklistedApps";
import AffilitestData from "pages/reports/AffilitestData";
import DiscontinuedOffers from "pages/reports/DiscontinuedOffers";
import PublisherFeed from "pages/reports/PublisherFeed";
import AdvertiserApps from "pages/reports/AdvertiserApps";
import OfferDetails from "pages/reports/OfferDetails";
import AutoTargetedPublishers from "pages/tools/AutoTargetedPublishers";
import ClickCapping from "pages/tools/ClickCapping";
import ImpressionCapping from "pages/reports/ImpressionCapping";
import ImpCapping from "pages/tools/ImpCapping";
import AutoPause from "pages/tools/AutoPause";
import PIERemainingBudget from "pages/reports/PIERemainingBudget";
import RejectedInstalls from "pages/reports/RejectedInstalls";
import RejectionRate from "pages/reports/RejectionRate";
import CRBySubpublisher from "pages/reports/CRBySubpublisher";
import RTB from "pages/reports/RTB";
import RtbTraffic from "pages/reports/RtbTraffic";
import RtbReport from "pages/reports/RtbReport";
import RtbExchangeDataReport from "pages/reports/RtbExchangeDataReport";
import UserData from "pages/reports/UserData";
import SideBySide from "pages/reports/SideBySide";
import TeamPerformance from "pages/reports/TeamPerformance";
import AdjustCohorts from "pages/reports/custom/AdjustCohorts";
import WhitelabelPublisherReport from "pages/reports/custom/WhitelabelPublisherReport";
import ScrubReport from "pages/reports/ScrubReport";
import BlockingRules from "pages/reports/BlockingRules";
import AddRemoveTargetedPublishers from "pages/tools/AddRemoveTargetedPublishers";
import MassBlock from "pages/tools/MassBlock";
import GloballyBlockedPubs from "pages/tools/GloballyBlockedPubs";
import Decode from "pages/tools/Decode";
import Encode from "pages/tools/Encode";
import OfflineOffers from "pages/tools/OfflineOffers";
import Sandbox from "pages/user/Sandbox";
import Profile from "pages/user/Profile";
import MassClone from "pages/tools/MassClone";
import ImpressionLinks from "pages/tools/Impressions";
import TrackingLinks from "pages/tools/Trackings";
import PIEOptimization from "pages/tools/Optimization";
import PublisherMaxRisk from "pages/tools/PublisherMaxRisk";
import CampaignSchedule from "pages/tools/CampaignSchedule";
import CampaignPacing from "pages/tools/CampaignPacing";
import TrafficMultiplier from "pages/tools/TrafficMultiplier";
import OfferGroups from "pages/tools/OfferGroups";
import CampaignOperations from "pages/tools/CampaignOperations";
import InvoiceIssuers from "pages/tools/InvoiceIssuers";
import InvoiceRecipients from "pages/tools/InvoiceRecipients";
import Invoices from "pages/tools/Invoices";
import AccountingOperations from "pages/tools/AccountingOperations";
import Transactions from "pages/tools/Transactions";
import Balances from "pages/tools/Balances";
import OfferPublisherProperties from "pages/tools/OfferPublisherProperties";

const App = () => {
  const [minBillableDate, setMinBillableDate] = React.useState<string>();
  const skin = React.useContext(SkinContext);
  const user = React.useContext(UserContext);
  const history = useHistory();

  React.useLayoutEffect(() => {
    $("body")
      .removeClass()
      .addClass(`hold-transition ${skin} layout-top-nav`)
      .trigger("resize");
  }, [skin]);

  React.useEffect(() => {
    fetch("/api/v1/settings/global")
      .then(res => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((json: any) => setMinBillableDate(json.minBillableDataInclusiveUtc))
      .catch(err => console.log(err));
  }, []);

  return (
    <LTE.Wrapper>
      <LTE.MainHeader>
        <Navbar fluid inverse collapseOnSelect={true}>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <b>Minimob</b>Adops
              </Link>
            </Navbar.Brand>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <LinkContainer to="/feeders">
                <NavItem>{Feeders.title}</NavItem>
              </LinkContainer>
              <NavDropdown
                id="reports"
                title="Reports"
                active={RegExp(/^\/reports/).test(history.location.pathname)}
              >
                <MenuItem header><b>OFFERS</b></MenuItem>
                {/*
                <LinkContainer to="/reports/convertingoffers">
                  <MenuItem>{ConvertingOffers.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/nonconvertingoffers">
                  <MenuItem>{NonConvertingOffers.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/directoffers">
                  <MenuItem>{DirectOffers.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/offerscountbyapi">
                  <MenuItem>{OffersCountByApi.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/offerscountbypackagename">
                  <MenuItem>{OffersCountByPackageName.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/affilitestdata">
                  <MenuItem>{AffilitestData.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/discontinuedoffers">
                  <MenuItem>{DiscontinuedOffers.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/reports/publisherfeed">
                  <MenuItem>{PublisherFeed.title}</MenuItem>
                </LinkContainer>
                {/*
                <LinkContainer to="/reports/advertiserapps">
                  <MenuItem>{AdvertiserApps.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/offerdetails">
                  <MenuItem>{OfferDetails.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/pieremainingbudget">
                  <MenuItem>{PIERemainingBudget.title}</MenuItem>
                </LinkContainer>
                */}
                <MenuItem divider />
                <MenuItem header><b>TRAFFIC</b></MenuItem>
                <LinkContainer to="/reports/scrubreport">
                  <MenuItem>{ScrubReport.title}</MenuItem>
                </LinkContainer>
                {/*
                <LinkContainer to="/reports/blockingrules">
                  <MenuItem>{BlockingRules.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/reports/rejectedinstalls">
                  <MenuItem>{RejectedInstalls.title}</MenuItem>
                </LinkContainer>
                {/*
                <LinkContainer to="/reports/rejectionrate">
                  <MenuItem>{RejectionRate.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/crbysubpublisher">
                  <MenuItem>{CRBySubpublisher.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/rtb">
                  <MenuItem>{RTB.title}</MenuItem>
                </LinkContainer>
                {/*<LinkContainer to="/reports/rtbtraffic">
                  <MenuItem>{RtbTraffic.title}</MenuItem>
                </LinkContainer>                
                <LinkContainer to="/reports/rtbreport">
                  <MenuItem>{RtbReport.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/reports/userdata">
                  <MenuItem>{UserData.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/reports/rtbexchangedatareport">
                  <MenuItem>{RtbExchangeDataReport.title}</MenuItem>
                </LinkContainer>
                <MenuItem divider />
                <MenuItem header><b>REVENUES</b></MenuItem>
                
                <LinkContainer to="/reports/sidebyside">
                  <MenuItem>{SideBySide.title}</MenuItem>
                </LinkContainer>
                {/*
                <LinkContainer to="/reports/teamperformance">
                  <MenuItem>{TeamPerformance.title}</MenuItem>
                </LinkContainer>
                <MenuItem divider />
                <MenuItem header><b>CUSTOM</b></MenuItem>
                <LinkContainer to="/reports/custom/adjustcohorts">
                  <MenuItem>{AdjustCohorts.title}</MenuItem>
                </LinkContainer>
                {user.isInRole("WhitelabelPublisherReport") && (
                  <LinkContainer to="/reports/custom/whitelabelpublisherreport">
                    <MenuItem>{WhitelabelPublisherReport.title}</MenuItem>
                  </LinkContainer>
                )}
                */}
              </NavDropdown>
              <NavDropdown
                id="tools"
                title="Tools"
                active={RegExp(/^\/tools/).test(history.location.pathname)}
              >
                <MenuItem header><b>TRAFFIC MANAGEMENT</b></MenuItem>
                {/*
                <LinkContainer to="/tools/autopause">
                  <MenuItem>{AutoPause.title}</MenuItem>
                </LinkContainer>
                */}
                {/*<LinkContainer to="/tools/clickcapping">
                  <MenuItem>{ClickCapping.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/tools/impcapping">
                  <MenuItem>{ImpCapping.title}</MenuItem>
                </LinkContainer>                
                <LinkContainer to="/tools/campaignpacing">
                  <MenuItem>{CampaignPacing.title}</MenuItem>
                </LinkContainer>
                {/*
                <LinkContainer to="/tools/campaignschedule">
                  <MenuItem>{CampaignSchedule.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/tools/trafficmultiplier">
                  <MenuItem>{TrafficMultiplier.title}</MenuItem>
                </LinkContainer>
                
                <MenuItem divider />
                <MenuItem header><b>PUBLISHER MANAGEMENT</b></MenuItem>
                {/*
                <LinkContainer to="/tools/autotargetedpublishers">
                  <MenuItem>{AutoTargetedPublishers.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/tools/addremovetargetedpublishers">
                  <MenuItem>{AddRemoveTargetedPublishers.title}</MenuItem>
                </LinkContainer>
                {/*<LinkContainer to="/tools/globallyblockedpubs">
                  <MenuItem>{GloballyBlockedPubs.title}</MenuItem>
                </LinkContainer>*/}
                {/*
                <LinkContainer to="/tools/pieoptimization">
                  <MenuItem>{PIEOptimization.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/publishermaxrisk">
                  <MenuItem>{PublisherMaxRisk.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/tools/decode">
                  <MenuItem>{Decode.title}</MenuItem>
                </LinkContainer>                
                <LinkContainer to="/tools/encode">
                  <MenuItem>{Encode.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/offlineoffers">
                  <MenuItem>{OfflineOffers.title}</MenuItem>
                </LinkContainer>
                <MenuItem divider />
                <MenuItem header><b>CAMPAIGN MANAGEMENT</b></MenuItem>
                <LinkContainer to="/tools/trackinglinks">
                  <MenuItem>{TrackingLinks.title}</MenuItem>
                </LinkContainer>
                {/*
                <LinkContainer to="/tools/blacklistedapps">
                  <MenuItem>{BlacklistedApps.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/massclone">
                  <MenuItem>{MassClone.title}</MenuItem>
                </LinkContainer>
                */}
                <LinkContainer to="/tools/offergroups">
                  <MenuItem>{OfferGroups.title}</MenuItem>
                </LinkContainer>                
                <LinkContainer to="/tools/offerpublisherproperties">
                  <MenuItem>{OfferPublisherProperties.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/campaignoperations">
                  <MenuItem>{CampaignOperations.title}</MenuItem>
                </LinkContainer>
                {/*
                {/*<MenuItem divider />
                <LinkContainer to="/tools/massblock">
                  <MenuItem>{MassBlock.title}</MenuItem>
                </LinkContainer>
                */}
                <MenuItem divider />
                <MenuItem header><b>ACCOUNTING</b></MenuItem>
                <LinkContainer to="/tools/invoiceissuers">
                  <MenuItem>{InvoiceIssuers.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/invoicerecipients">
                  <MenuItem>{InvoiceRecipients.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/accountingoperations">
                  <MenuItem>{AccountingOperations.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/invoices">
                  <MenuItem>{Invoices.title}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/tools/transactions">
                  <MenuItem>{Transactions.title}</MenuItem>
                </LinkContainer>                
                <LinkContainer to="/tools/balances">
                  <MenuItem>{Balances.title}</MenuItem>
                </LinkContainer>
                {/*
                */}
              </NavDropdown>
              
            </Nav>
            <Nav pullRight>
              <NavDropdown
                id="user"
                title={user.findFirst("unique_name")}
                active={RegExp(/^\/user/).test(history.location.pathname)}
              >
                <LinkContainer to="/user/profile">
                  <MenuItem>{Profile.title}</MenuItem>
                </LinkContainer>
                {user.isInRole("admin") && (
                  <LinkContainer to="/user/sandbox">
                    <MenuItem>{Sandbox.title}</MenuItem>
                  </LinkContainer>
                )}
                <MenuItem
                  href="/swagger"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  API Documentation <i className="fa fa-external-link"></i>
                </MenuItem>
                <MenuItem divider />
                <MenuItem
                  onClick={e => {
                    e.preventDefault();
                    user.logout().then(() => history.push("/"));
                  }}
                >
                  Logout
                </MenuItem>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </LTE.MainHeader>
      <LTE.ContentWrapper>
        {/* prettier-ignore */}
        <Switch>
          <Route path="/feeders" component={Feeders} />
          <Route path="/reports/convertingoffers" component={ConvertingOffers} />
          <Route path="/reports/nonconvertingoffers" component={NonConvertingOffers} />
          <Route path="/reports/directoffers" component={DirectOffers} />
          <Route path="/reports/offerscountbyapi" component={OffersCountByApi} />
          <Route path="/reports/offerscountbypackagename" component={OffersCountByPackageName} />
          <Route path="/reports/affilitestdata" component={AffilitestData} />
          <Route path="/reports/discontinuedoffers" component={DiscontinuedOffers} />
          <Route path="/reports/publisherfeed" component={PublisherFeed} />
          <Route path="/reports/advertiserapps" component={AdvertiserApps} />
          <Route path="/reports/offerdetails" component={OfferDetails} />
          <Route path="/reports/impressioncapping" component={ImpressionCapping} />
          <Route path="/reports/rejectedinstalls" component={RejectedInstalls} />
          <Route path="/reports/rejectionrate" component={RejectionRate} />
          <Route path="/reports/crbysubpublisher" component={CRBySubpublisher} />
          <Route path="/reports/rtb" component={RTB} />
          <Route path="/reports/rtbtraffic" component={RtbTraffic} />
          <Route path="/reports/rtbreport" component={RtbReport} />
          <Route path="/reports/userdata" component={UserData} />
          <Route path="/reports/rtbexchangedatareport" component={RtbExchangeDataReport} />
          <Route path="/reports/sidebyside" component={SideBySide} />
          <Route path="/reports/teamperformance" component={TeamPerformance} />
          <Route path="/reports/custom/adjustcohorts" component={AdjustCohorts} />
          <Route path="/reports/blockingrules" component={BlockingRules} />
          <Route path="/reports/pieremainingbudget" component={PIERemainingBudget} />
          {user.isInRole("WhitelabelPublisherReport") && (
            <Route path="/reports/custom/whitelabelpublisherreport" component={WhitelabelPublisherReport} />
          )}
          <Route path="/reports/scrubreport">
            <ScrubReport
              allowBigQuery={user.isInRole("ScrubReportBigQuery")}
              minBillableDate={minBillableDate}
            />
          </Route>
          
          <Route path="/tools/autopause" component={AutoPause} />
          <Route path="/tools/clickcapping" component={ClickCapping} />
          <Route path="/tools/impcapping" component={ImpCapping} />
          <Route path="/tools/autotargetedpublishers" component={AutoTargetedPublishers} />
          <Route path="/tools/addremovetargetedpublishers" component={AddRemoveTargetedPublishers} />
          <Route path="/tools/massblock" component={MassBlock} />
          <Route path="/tools/massclone" component={MassClone} />
          <Route path="/tools/globallyblockedpubs" component={GloballyBlockedPubs} />
          <Route path="/tools/decode" component={Decode} />
          <Route path="/tools/encode" component={Encode} />
          <Route path="/tools/offlineoffers" component={OfflineOffers} />
          <Route path="/tools/impressionlinks" component={ImpressionLinks} />
          <Route path="/tools/trackinglinks" component={TrackingLinks} />
          <Route path="/tools/pieoptimization" component={PIEOptimization} />
          <Route path="/tools/publishermaxrisk" component={PublisherMaxRisk} />
          <Route path="/tools/campaignschedule" component={CampaignSchedule} />
          <Route path="/tools/campaignpacing" component={CampaignPacing} />
          <Route path="/tools/trafficmultiplier" component={TrafficMultiplier} />
          <Route path="/tools/blacklistedapps" component={BlacklistedApps} />
          <Route path="/tools/offergroups" component={OfferGroups} />
          <Route path="/tools/offerpublisherproperties" component={OfferPublisherProperties} />
          
          <Route path="/tools/campaignoperations">
            <CampaignOperations
              user={user}
              />
          </Route>
          
          <Route path="/tools/invoiceissuers" component={InvoiceIssuers} />
          <Route path="/tools/invoicerecipients" component={InvoiceRecipients} />
          <Route path="/tools/accountingoperations" component={AccountingOperations} />
          <Route path="/tools/invoices">
            <Invoices
              userIsAccountingAdmin={user.isInRole("accountingAdmin")}
            />
          </Route>
          <Route path="/tools/transactions">
            <Transactions
              userIsAccountingAdmin={user.isInRole("accountingAdmin")}
            />
          </Route>
          <Route path="/tools/balances" component={Balances} />
          <Route path="/user/profile" component={Profile} />
          {user.isInRole("admin") && (
            <Route path="/user/sandbox" component={Sandbox} />
          )}
          <Route path="/" exact component={Home} />
          <Route path="*" component={NotFound} />
        </Switch>
      </LTE.ContentWrapper>
    </LTE.Wrapper>
  );
};

export default App;
