import React from "react";
import { Box } from "components/admin-lte";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Row,
  Col
} from "react-bootstrap";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";

export default class EditConversionsFiringDialog extends React.Component {
  static title = "Edit Conversion Firing & rate";

  constructor(props) {
    super(props);
    this.state = {
      offerId: props.offerId||null,
      trafficsource: props.trafficsource||null,
      conversions_firing: props.conversions_firing||null,
      conversions_firing_rate: props.conversions_firing_rate||null,
      error: null,
      busy: false,
      message: null
    };
    this.SaveChanges = this.SaveChanges.bind(this);
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);

    return (

      <Modal bsSize="large" show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton disabled={this.state.busy}>
          <Modal.Title>{EditConversionsFiringDialog.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorSummary != null && (
            <div className="alert alert-danger">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ error: null })}
              >
                &times;
              </button>
              {errorSummary}
            </div>
          )}
          {this.state.message != null && (
            <div className="alert alert-success">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={() => this.setState({ message: null })}
              >
                &times;
              </button>
              {this.state.message}
            </div>
          )}
          <p>
            Please fill the conversions firing and the conversion firing rate .
          </p>
          <Box.Body>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Conversions Firing</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    min={0}
                    max={1000}
                    onChange={e =>
                      this.setState({ conversions_firing: e.target.value })
                    }
                    defaultValue={this.state.conversions_firing}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>conversions firing every #clicks</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="number"
                    min={0}
                    max={1000000000}
                    onChange={e =>
                      this.setState({ conversions_firing_rate: e.target.value })
                    }
                    defaultValue={this.state.conversions_firing_rate}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Box.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            // disabled={
            //   !this.state.conversions_firing ||
            //   !this.state.conversions_firing_rate
            // }
            bsStyle="primary"
            onClick={this.SaveChanges}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  SaveChanges() {
    this.setState({
      busy: true,
      message: null,
      error: null
    });

    axios({
      url: "/api/v1/tools/updateconversionsfiring",
      method: "post",
      data: {
        offerId: this.state.offerId,
        trafficsource: this.state.trafficsource,
        conversions_firing: this.state.conversions_firing,
        conversions_firing_rate: this.state.conversions_firing_rate
      }
    })
      .then(res => {
        this.setState({
          busy: false,
          message: "The operation completed successfully.",
          error: null
        });
        this.props.onHide();
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        this.setState({
          busy: false,
          message: null,
          error
        });
      });
  }
}
