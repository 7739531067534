import React from "react";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import EditDialog from "./EditConversionsFiring";

import {Button, ButtonGroup, FormGroup, Row, Col} from "react-bootstrap";
import { UserContext } from "lib/user";
import {  PublisherPicker } from "components/pickers/SelectPickers";

import ReactDOMServer from "react-dom/server";

function renderCopy(d, t, r, m) {
  if (t === "display") {
    if (r.clickTrackingUrl && r.clickTrackingUrl.length > 0) {
      return (ReactDOMServer.renderToStaticMarkup(
          <button type='button' className='btn btn-default btn-small'>
            <i className="fa fa-copy fa-1_4x" title="Copy Click Tracking Url to clipboard"></i>
          </button>
        )
      );
    } else {
      return "";
    }
  }
  return d;
}

export default class OfferPublisherProperties extends React.Component {
  static title = "Offer Publisher Properties !!!";

  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      tableUrl:"/api/v1/tools/OfferPublisherProperties",
      loading: true,
      showEditFor: null,
      publisherId: null,
      publisherName: null
    };
    this.handleReload = this.handleReload.bind(this);
    this.getTableUrl = this.getTableUrl.bind(this);
    this.selectPublisher = this.selectPublisher.bind(this);
    //this.handleAddClick = this.handleAddClick.bind(this);
    //this.handleAddEnd = this.handleAddEnd.bind(this);
    //this.colorizeRow = this.colorizeRow.bind(this);
  }

  componentDidMount() {
    this.selectPublisher(this.state.publisherId);
  }
  
  render() {
    const { showEditFor } = this.state;
    return (
      <>
        <PageLayout
          breadcrumbs={["Tools"]}
          title={OfferPublisherProperties.title}
          description={
            <p>This is a tool for automated install sending....</p>
          }
        >
          <UserContext.Consumer>
          {user => (
            <Box busy={this.state.loading}>
              <Box.Header>                
                <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                  {/*<ButtonGroup bsSize="small">*/}
                  {/*  <Button*/}
                  {/*    bsStyle="primary"*/}
                  {/*    onClick={this.handleAddClick.bind(this,user)}*/}
                  {/*  >*/}
                  {/*    <i className="fa fa-plus"></i> Add Group*/}
                  {/*  </Button>*/}
                  {/*</ButtonGroup>*/}
                  
                </DataTable.Toolbar>
                <br/>
                <Row>
                  <Col xs={12} sm={6} lg={3}>
                    <FormGroup>
                      <label>Publisher</label>
                      <PublisherPicker
                        className="form-control"
                        placeholder="Enter id or name"
                        onChange={(publisherId,publisherName) => this.selectPublisher(publisherId,publisherName)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Box.Header>
              <Box.Body>
                <DataTable
                  className="table table-condensed table-striped table-hover"
                  ref={this.tableRef}
                  ajax={{
                    url: this.getTableUrl(), //"/api/v1/tools/OfferPublisherProperties",
                    dataSrc: 'items'
                  }}
                  onLoading={()=> this.setState({ loading: true })}
                  onLoaded={() => this.setState({ loading: false })}
                  createdRow={ this.colorizeRow }
                  onUserCommand={({ commandData }) =>{
                    if (user.tokenHasExpired) {
                      window.location.replace('/login');
                      return;
                    }
                    else {
                      if(commandData.action === "showEdit"){
                        this.setState({ showEditFor: commandData  });
                      }      
                    }
                  }}
                  columns={[
                    {
                      title: "_id",
                      data: "_id",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "offerid",
                      data: "offerId",
                      //name: "offerId",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "publisher id",
                      data: "trafficsource",
                      name: "trafficsource",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "incent",
                      data: "incent",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "as_cpi",
                      data: "as_cpi",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "s2s_click",
                      data: "s2s_click",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "id_matching",
                      data: "id_matching",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "daily_cap_limit",
                      data: "daily_cap_limit",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "view_through_percent",
                      data: "view_through_percent",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "payout",
                      data: "payout",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "inactiveHours",
                      data: "inactiveHours",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d?JSON.stringify(d):""
                      }
                    },
                    {
                      title: "additionalTargetedCountries",
                      data: "additionalTargetedCountries",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d?JSON.stringify(d):""
                      }
                    },
                    {
                      title: "conversions_firing_every_#clicks",
                      data: "conversion_firing_rate",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    {
                      title: "conversions_firing",
                      data: "conversion_firing",
                      width: "5%",
                      defaultContent: "",
                      render: (d, t, r, m) => {
                        return d
                      }
                    },
                    DataTable.createUserCommandColumn({
                      title: "",
                      data: null,
                      className: "text-right",
                      orderable: false,
                      defultContent: "",
                      width: "30px",
                      commandName: "",
                      commandData: ({ rowData }) => ({
                        action: 'showEdit',
                        offerId: rowData.offerId,
                        trafficsource: rowData.trafficsource,
                        conversions_firing: rowData.conversion_firing,
                        conversions_firing_rate: rowData.conversion_firing_rate,
                      }),
                      render: (d, t, r, m) => ( "<div><button type='button' class='btn btn-default'> Edit </button></div>")
                    }),
                  ]}
                  order={[[0, "asc"]]}
                />
                
                {this.state.publisherId!==null && (
                  <Row>
                    <Col sm={12} lg={6}>
                      {/*<div id="indicator-table-wrapper">*/}
                      {/*  <table id="indicator-table">*/}
                      {/*    <tbody>*/}
                      {/*    <tr>*/}
                      {/*      <td className="indicator-color">*/}
                      {/*        <div className="indicator-color-div row-bg-warning"></div>*/}
                      {/*      </td>*/}
                      {/*      <td className="indicator-text small-font">Selected publisher is not included in some offers in group</td>*/}
                      {/*    </tr>*/}
                      {/*    <tr>*/}
                      {/*      <td className="indicator-color">*/}
                      {/*        <div className="indicator-color-div row-bg-danger"></div>*/}
                      {/*      </td>*/}
                      {/*      <td className="indicator-text small-font">Selected publisher is not included in all offers in group</td>*/}
                      {/*    </tr>*/}
                      {/*    </tbody>*/}
                      {/*  </table>*/}
                      {/*</div>*/}
                    </Col>
                  </Row>  
                )}
              </Box.Body>
            </Box>
          )}
          </UserContext.Consumer>
        </PageLayout>
        {this.state.showEditFor && (
          <EditDialog
            isNew={false}
            offerId={showEditFor.offerId}
            trafficsource={showEditFor.trafficsource}
            conversions_firing={showEditFor.conversions_firing}
            conversions_firing_rate={showEditFor.conversions_firing_rate}
            
            onHide={() => {
              this.setState({ showEditFor: null });
              this.tableRef.current.reload();
            }}
          />
        )}
      </>
    );
  }

  constructTableUrl(){
    var publisherId=this.state.publisherId||null;

    var qs = "";
    if(publisherId!==null&&publisherId.length>0){
      qs = "?publisherId="+publisherId;
    }
    var newUrl = this.state.tableUrl+qs;

    return newUrl;
  }

  handleReload(){
    var newUrl = this.constructTableUrl();
    this.tableRef.current.setNewUrl(newUrl);
  }

  getTableUrl(){
    return this.constructTableUrl();
  }
  
  // handleAddEnd(name,ids){
  //   //TODO: validation
  //   if(ids && ids.length>0){
  //     ids = ids.join(",");
  //   }
  //  
  //   this.setState({
  //     busy: true,
  //     message: null,
  //     error: null
  //   });
  //  
  //   axios({
  //     url: "/api/v1/tools/addoffergroup",
  //     method: "post",
  //     data: {
  //       Name: name,
  //       Offers: JSON.stringify(ids)
  //     }
  //   })
  //     .then(res => {
  //       this.setState({
  //         showAddGroup: false,
  //         busy: false,
  //         message: "The operation completed successfully.",
  //         error: null
  //       });
  //
  //       this.tableRef.current.reload();
  //     })
  //     .catch(err => {
  //       const error = err.response
  //         ? err.response.data
  //         : err.request
  //           ? err.request
  //           : err.message;
  //       this.setState({
  //         busy: false,
  //         message: null,
  //         error
  //       });
  //     });
  // }
 
  selectPublisher(publisherId, publisherName) {
    this.setState({ publisherId: publisherId, publisherName: publisherName }, () => {
      this.handleReload();
      const api = this.tableRef.current.api;
      if(publisherId!==null && publisherId.length>0){
        api.columns('trafficsource:name').search(publisherId, false).draw();
        //api.columns(".columnset-publisher-related").visible(true);
      }else{
        //api.columns(".columnset-publisher-related").visible(false);
        api.columns().search('').draw();
      }
      
      //api.draw(false);
    });
  }

  // handleAddClick(user,e){
  //   if (user.tokenHasExpired) {
  //     e.preventDefault();
  //     window.location.replace('/login');
  //   } else {
  //     //handleAddStart()
  //     this.setState({showAddGroup:true});
  //   }
  // }

  // colorizeRow(row, data, dataIndex) {
  //  
  //   var publisherId = this?.state?.publisherId||null;
  //   var found = false;
  //   var foundCount = 0;
  //  
  //   if (publisherId) {
  //     if (data.publishersByOffer) {
  //      
  //       data.publishersByOffer.forEach(offer => {
  //         found = _.includes((offer.targetedTrafficSources||[]), publisherId);
  //         if(found){
  //           ++foundCount;
  //           //return false;
  //         }
  //       });
  //      
  //       if(foundCount === 0){
  //         $(row).addClass( 'row-bg-danger' );
  //       }else if(foundCount < data.publishersByOffer.length){
  //         $(row).addClass( 'row-bg-warning' );
  //       }
  //     }
  //    
  //   }
  // }
  
}
